body {
	margin-bottom: 56px;
	background-color: #434546;
	font-family: 'Metropolis', sans-serif;
	color: #e5dfcb;
	overflow: overlay;
	overflow-x: hidden;
	line-height: 1.4;
	padding-bottom: 5vh;
}

.noscript-warning {
	width: 100%;
	/* Or specify a fixed width if you want */
	max-width: 600px;
	/* Optional: if you want to limit the width */
	text-align: center;
	background-color: #eba656;
	color: #212223;
	padding: 10px;
	font-size: 20px;
	font-family: 'Metropolis', sans-serif;
	font-weight: bold;
	position: fixed;
	z-index: 9000;
	top: 50%;
	/* Center vertically */
	left: 50%;
	/* Center horizontally */
	transform: translate(-50%, -50%);
	/* Adjust the position to truly center the element */
}

strong,
b,
.bold {
	font-weight: bold;
}

html {
	font-size: 16px;
}

@media (min-width: 992px) {
	.row.no-negative-margins-lg {
		margin-right: 0;
		margin-left: 0;
	}

	.row.no-negative-margins-lg>.col,
	.row.no-negative-margins-lg>[class*="col-"] {
		padding-right: 15px;
		padding-left: 15px;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
small,
.extra-small {
	line-height: 1.4;
}

h1 {
	font-size: 2.986rem;
}

h2 {
	font-size: 2.488rem;
}

h3 {
	font-size: 2.074rem;
}

h4 {
	font-size: 1.728rem;
}

h5 {
	font-size: 1.44rem;
}

h6 {
	font-size: 1.2rem;
}

p {
	font-size: 1rem;
}

small {
	font-size: 0.833rem;
}

.extra-small {
	font-size: 0.694rem;
}

nav.navbar {
	position: fixed !important;
	/* Important to override any inline or file-based styles */
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
	/* Ensuring it's on top of other content */
	width: 100%;
	/* Ensures the navbar extends full width */
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	background: rgba(0, 0, 0, 0.5);
	border-bottom: 2px solid #e5dfcb;
}

.navbar {
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	background: rgba(0, 0, 0, 0.5);
	z-index: 1020;
	border-bottom: 2px solid #e5dfcb;
}

.navbar-brand,
.navbar .nav-link {
	color: #e5dfcb !important;
	transition: color 0.3s ease-in-out;
}

.navbar-brand:hover,
.navbar-brand:focus {
	color: #eba656 !important;
}

.navbar .nav-item .nav-link.active,
.navbar .nav-item:hover .nav-link {
	color: #eba656 !important;
}

.navbar-collapse {
	justify-content: flex-start;
}

.navbar-toggler,
.navbar-toggler:focus {
	border: none !important;
	outline: none;
}

.navbar-toggler {
	border: none;
	background: transparent;
}

.line {
	fill: none;
	stroke: #e5dfcb;
	stroke-width: 6;
	transition: stroke-dasharray 0.4s, stroke-dashoffset 0.4s;
}

.line1,
.line3 {
	stroke-dasharray: 60 207;
}

.line2 {
	stroke-dasharray: 60 60;
}

.navbar-toggler.toggled .line1,
.navbar-toggler.toggled .line3 {
	stroke-dasharray: 90 207;
	stroke-dashoffset: -134;
}

.navbar-toggler.toggled .line2 {
	stroke-dasharray: 1 60;
	stroke-dashoffset: -30;
}

.navbar-toggler {
	border: none;
	position: relative;

}

.navbar-toggler-icon {
	background-image: none;

	position: relative;
	width: 22.5px;

	height: 2px;

	background-color: #e5dfcb;

	transition: all 0.3s ease-in-out;

	display: block;
	margin: 0 auto;

}

.navbar-toggler-icon:before,
.navbar-toggler-icon:after {
	content: '';
	position: absolute;
	left: 0;

	width: 100%;
	height: 2px;

	background-color: #e5dfcb;

	transition: all 0.3s ease-in-out;

}

.navbar-toggler-icon:before {
	top: -7.5px;

}

.navbar-toggler-icon:after {
	bottom: -7.5px;

}

.navbar-toggler.toggled .navbar-toggler-icon {
	background-color: transparent;

}

.navbar-toggler.toggled .navbar-toggler-icon:before {
	transform: rotate(45deg) translate(5px, 5.5px);

}

.navbar-toggler.toggled .navbar-toggler-icon:after {
	transform: rotate(-45deg) translate(5px, -5px);

}

.hb {
	width: 30px;
	display: block;
	margin: auto;
}

@media (min-width: 992px) {
	.navbar .nav-item:not(:last-child) {
		margin-right: 150px;
	}
}

.portfolio-item {
	margin-bottom: 30px;
}

.portfolio-item img {
	width: 200%;
	display: block;
	aspect-ratio: 16 / 9;
	object-fit: cover;
}

@media (min-width: 1200px) {
	.portfolio-item {
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}

	.container {
		max-width: 75%;
	}
}

.portfolio-item .portfolio-text {
	padding: 15px 10px;
}

.portfolio-item .category {
	color: #eba656;
	font-size: 0.9em;
	font-weight: bold;
}

.portfolio-item .title {
	color: #e5dfcb;
	font-size: 1.2em;
}

@media (max-width: 991px) {
	.portfolio-item {
		margin-bottom: 15px;
	}
}

.footer {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0) 100%);
	color: white;
	z-index: 1030;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 1rem;
}

@media (max-width: 767px) {
	.footer {
		padding: 10px 1rem;
	}

	.navbar-brand,
	.nav-link {
		font-size: 1rem;
	}
}

@font-face {
	font-family: 'Metropolis';
	src: url('../assets/font/Metropolis-Regular.woff2') format('woff2'),
		url('../assets/font/Metropolis-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('../assets/font/Metropolis-Bold.woff2') format('woff2'),
		url('../assets/font/Metropolis-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}


.footer-content {
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: center;
	padding: 15px 0;
	color: #e5dfcb;
}

.footer-name,
.footer-page {
	z-index: 2;
}

.footer-line {
	flex-grow: 1;
	background: #e5dfcb;
	height: 2px;
	margin: 0 30px;
	top: 0.5em;
}

.grid-sizer {
	width: 33.333%;
}

::-webkit-scrollbar {
	width: 12px;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	background: #E5DFCA;
	border-radius: 6px;
	border: 4px solid transparent;
	background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
	border: 2px solid #bcb6a3;
	background: #E5DFCA;
}

.title-item .no-margin-top {
	margin-top: 0vh;
}


.project-title {
	margin-top: 20vh;
	margin-bottom: 2vh;
}

/* Styles for links within elements with the 'project-description' class */
.project-description a {
    text-decoration: none;  /* Removes the underline from links */
    color: #EBA656;         /* Sets the link color */
    font-weight: bold;      /* Optional: makes links bold */
}

/* Visited links within 'project-description' */
.project-description a:visited {
    color: #c97a2f; /* Darker shade for visited */
}

/* Hover links within 'project-description' */
.project-description a:hover {
    text-decoration: underline; /* Adds an underline on hover */
    color: #ffd199; /* Lighter shade for hover */
}

/* Active links within 'project-description' */
.project-description a:active {
    color: #b56423; /* Even darker shade for active */
}



.project-description {
	max-width: 60ch;
	margin-bottom: 20vh;
	margin-right: auto;
	font-size: 1rem;
	line-height: 1.6;
}

.project-description-contact {
	max-width: 60ch;
	margin-bottom: 0vh;
	margin-right: auto;
	font-size: 1rem;
	line-height: 1.6;
}

@media (max-width: 768px) {
	.project-description {
		max-width: 90%;
		margin-bottom: 0vh;
	}

	.project-title {
		margin-top: 5vh;
	}
}

.section-title {
	color: #e5dfcb;
	text-align: left;
	width: 100%;
	margin-top: 10vh;
}

.story-title {
	color: #e5dfcb;
	text-align: left;
	width: 100%;
	margin-top: 6vh;
}

.story-text {
	color: #e5dfcb;
	text-align: left;
	width: 100%;
	margin-top: 10vh;
	margin-bottom: 10vh;
}

.grid-item.title-item {
	width: 100%;
	order: -1;
}

.project-category {
	color: #eba656;
	font-size: 0.9em;
	font-weight: bold;
	padding-top: 12vh;
	/* Default padding for larger screens */
	margin-bottom: 10px;
}

@media (max-width: 767px) {
	.project-category {
		padding-top: 4vh;
		/* Smaller padding for mobile screens */
	}
}


.project-title {
	margin-top: 0;
}


.portfolio-item a {
	display: block;
	position: relative;
	overflow: hidden;
}

.portfolio-item a .image-container {
	position: relative;
	overflow: hidden;
	aspect-ratio: 16 / 9;  /* This sets the width and height to be equal */
}

.image-container img {
    object-fit: cover; /* Ensures the image covers the full container */
    object-position: center; /* Aligns the image centrally */
    width: 100%; /* Ensures the image stretches to cover the container width */
    height: 100%; /* Ensures the image stretches to cover the container height */
}



.portfolio-item a img {
	transition: transform 0.23s ease-in-out;
	display: block;
	width: 100%;
}





.portfolio-item a:hover,
.portfolio-item a:hover .portfolio-text {
	text-decoration: none;
	/* Removes the underline on hover */
}


.portfolio-item a .portfolio-text {
	position: relative;
	z-index: 2;
	border-top: 2px solid #e5dfcb;
	/* This ensures the text is above the overlay */
	background-color: #212223;
}


.video-wrapper {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.responsive-iframe {
	position: relative;
	overflow: hidden;
	padding-top: 56.25%;
	/* 16:9 Aspect Ratio */
}

.responsive-iframe iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.grid-item video {
	width: 100%;
	/* Occupy full width of grid item */
	height: auto;
	/* Maintain aspect ratio */
	object-fit: cover;
	/* Cover container, may crop sides of the video */
}


.fancybox-caption__body {
	font-size: 1.2rem;
	font-weight: bold;
	color: #e5dfcb;
}

@media (max-width: 768px) {
	.portfolio-item:first-child {
		margin-top: 0px;
		/* Adjust the value based on your navbar's height */
	}
}

::selection {
	background-color: #eba656;
	color: #434546;
}

img,
video {
	transition: transform 0.23s ease-in-out, box-shadow 0.23s ease-in-out;
	will-change: transform, box-shadow;
}




img:hover,
video:hover {
	transform: scale(1.03);
	/* Slightly enlarges the image or video */
	box-shadow: 0 0px 20px rgba(0, 0, 0, 0.3);
}


/* disable the normal image on the index.html thumbnails */
.image-container img:hover,
.image-container video:hover {
	transform: none;
	box-shadow: none;
}


.fancybox-content {
	border-radius: 16px;
}


/* Reapply the original styles without hover effects for images and videos inside Fancybox */
.fancybox-slide img,
.fancybox-slide video {
	transform: scale(1);
	/* Reset scaling */
	box-shadow: none;
	/* Remove shadow */
	transition: none;
	/* Remove transition to prevent any hover effects */
}

.scroll-indicator {
	position: fixed;
	bottom: 78%;
	/* Starting position */
	right: 10%;
	z-index: 100;
	/* High enough to be on top of other content */
	opacity: 1;
	/* Fully visible initially */
	transition: opacity 0.5s ease, bottom 0.5s ease;
	/* Smooth transitions for fading and moving */
}

.scroll-indicator img {
	display: block;
	width: 100px;
	/* Adjust based on your SVG size */
	height: auto;
	animation: rotate360 infinite 10s linear;
}

@keyframes rotate360 {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.py-5 {
	padding-top: 6rem !important;
}

img,
video {
	border-radius: 16px;
	/* Adjust this value to increase or decrease the roundness */
	overflow: hidden;
	/* Ensures no part of the image or video spills outside the rounded corners */
}

iframe {
	border-radius: 16px;
	/* Adjust this value to set the roundness of the corners */
	overflow: hidden;
	/* Prevents content from spilling outside the rounded corners */
	/* Optional: you might want to add a border or shadow here */
}

.portfolio-item a .image-container,
.video-wrapper {
	position: relative;
	overflow: hidden;
	/* This will clip any overflowing content, including during scale transforms */

	/* Maintain the rounded corners here */
}

.portfolio-item a img,
.video-wrapper video {
	transition: transform 0.23s ease-in-out;
	display: block;
	width: 100%;
	border-radius: 16px;
	/* Apply rounded corners to the images/videos themselves */
}

.portfolio-item a:hover img,
.video-wrapper video:hover {
	border-radius: 16px;
	/* Re-apply the rounded corners to avoid clipping issues */
}





.portfolio-item a {
	display: block;
	overflow: visible;
	/* Change from hidden to visible */
	position: relative;
	/* Ensure positioning context */
}



.portfolio-item img {
	display: block;
	overflow: visible;
	/* Change from hidden to visible */
	position: relative;
	/* Ensure positioning context */
}


.atropos-inner {
	border-radius: 16px;
}

.atropos-inner .image-container img.img-fluid.responsive-img {
    position: relative !important;
	margin: -8% 0 0px -15%;
    width: 130% !important;
    height: 130% !important;
	
}




.img-fluid {
	max-width: none;
}



.portfolio-item .portfolio-number {
	position: absolute;
	top: 14px;
	/* Adjust this value as needed to position it at the top right */
	right: 10px;
	/* Adjust this value as needed to position it at the top right */
	color: #eba656;
	/* Same color as the category */
	font-size: 0.9em;
	/* Same font size as the category */
	font-weight: bold;
	/* Same font weight as the category */
	z-index: 3;
	/* Ensure it's on top of other content */

}










.modal-content {
    background-color: #434546; 
    color: #e5dfcb; 
    border-radius: 16px;
}

.modal-header,
.modal-footer {
    border-color: #555; 
}




/* Primary button styling with increased specificity */
html .btn-primary {
    background-color: #eba656 !important; /* Primary button color to match your theme */
    border-color: #eba656 !important; /* Consistent border color */
	color: #434546 !important; 
}

/* Hover state for primary button with increased specificity */
html .btn-primary:hover {
    background-color: #ffd199 !important; /* Lighter shade for hover to match link hover */
    border-color: #ffd199 !important; /* Ensuring the border matches the background on hover */
}

/* Focus state for primary button with increased specificity */
html .btn-primary.focus,
html .btn-primary:focus {
	color: #434546 !important;
    background-color: #eba656 !important; /* Keep primary color on focus */
    border-color: #d9a257 !important; /* Slightly darker border for better visibility on focus */
    box-shadow: 0 0 0 0.2rem rgba(235, 166, 86, 0.5); /* Soft glow with color matching the button */
}

/* Active state for primary button with increased specificity */
html .btn-primary:active,
html .btn-primary:not(:disabled):not(.disabled).active,
html .show > .btn-primary.dropdown-toggle {
    background-color: #dba456 !important; /* Slightly darker for active state */
    border-color: #cba256 !important; /* Matching darker border for active state */
	color: #434546 !important;
    box-shadow: 0 0 0 0.2rem rgba(235, 166, 86, 0.5); /* Consistent with focus state */
}





.btn-primary {
	border-radius: 12px;
}

.btn-secondary {
	border-radius: 12px;
	color:#E5DFCB;
}

.btn-secondary:hover {
	color:#E5DFCB;
}

.modal-footer {
	border-color: #E5DFCB;
}

.modal-header {
	border-color: #E5DFCB;
}



.btn-close {
    width: 1em;
    height: 1em;
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="%23e5dfcb"><path d="M.293.293a1 1 0 0 1 1.414 0L8 6.586l6.293-6.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z"/></svg>') no-repeat center center / 100% auto;
    opacity: 1;
	margin-top:7px;
}



.modal-header .btn-close {
    color: #e5dfcb; 
    filter: drop-shadow(0 0 2px #434546); 
}

.modal-body::-webkit-scrollbar {
    width: 8px;
}
.modal-body::-webkit-scrollbar-track {
    background: #2f2f2f; 
}
.modal-body::-webkit-scrollbar-thumb {
    background-color: #e5dfcb; 
    border-radius: 4px;
}

.modal-title {
    color: #eba656; 
}

.modal-header .btn-close {
    color: #e5dfcb; 
    border: none; 
    opacity: 1; 
}

.modal-header .btn-close:hover {
    color: #eba656; 
	background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="%23eba656"><path d="M.293.293a1 1 0 0 1 1.414 0L8 6.586l6.293-6.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z"/></svg>') no-repeat center center / 100% auto;
}

.modal-header .btn-close:focus {
    box-shadow: none; 
    outline: none; 
}

.modal-dialog {
    display: flex; 
    align-items: center; 
    min-height: calc(100vh - 1rem); 
    margin: 0.5rem auto; 
}

.modal-content {
    overflow-y: auto; 
    max-height: 90vh; 
    position: relative; 
}

@media (max-width: 576px) {
    .modal-dialog {
        margin: 10px; 
        min-height: calc(100vh - 20px); 
    }
}


.thumb-border {
    border: 0.1rem solid grey;
}